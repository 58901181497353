export const JobData = [
  {
    id: 1,
    company: "Belkin Lab",
    title: "Undergraduate Student Researcher",
    startDate: "September 2023",
    endDate: "Present",
    bullets: [
      "Meeting weekly with the professor to experiment with adding feature learning to XGBoost.",
      "Researching further how deep neural networks learn features on a variety of computer vision and tabular tasks.",
      "Verified the validity of the Deep Neural Feature Ansatz to understand how deep neural networks learn features.",
    ],
    tags: ["Python", "Pytorch", "Jupyter Notebooks", "LateX", "Docker", "Yaml"],
  },
  {
    id: 2,
    company: "UCSD Data Science",
    title: "Data Science Tutor",
    startDate: "January 2022",
    endDate: "Present",
    bullets: [
      "Providing office hours on topics ranging from Data Structures and Algorithms to Advanced Machine Learning.",
      "Optimized Python grading scripts to adhere to OOP best practices and automated grading for three different classes, while also generalizing the scripts for other undergraduate courses.",
      "Created and graded assignments in order to ensure they are fair, well structured, and test concepts thoroughly.",
    ],
    tags: ["Python", "Pandas", "Numpy", "Scikit-Learn"],
  },
  {
    id: 3,
    company: "UCSD IT",
    title: "Student Developer",
    startDate: "June 2023",
    endDate: "September 2023",
    bullets: [
      "Analyzed and visualized complex student datasets with over 100 million data points in Cognos enabling managers to make data-driven decisions and modernize forward-facing dashboards.",
      "Implemented and deployed essential .NET API endpoints, automating student SQL data updates university-wide, resulting in the retirement of legacy systems.",
      "Optimized API speed and error solving by 10% with Open Telemetry tracing and metrics.",
    ],
    tags: [
      "C#",
      ".Net",
      "Python",
      "SQL",
      "Cognos",
      "Airflow",
      "Jira",
      "Bit Bucket",
      "CI/CD",
    ],
  },
  {
    id: 4,
    company: "UC San Diego",
    title: "Full Stack Developer Intern",
    startDate: "September 2022",
    endDate: "June 2023",
    bullets: [
      "Developed and maintain a web application that helps recreation staff manage UCSDs first Esports center.",
      "Helped managers effectively schedule staff during busy and light hours saving 10% of the staffing budget.",
      "Used ReactJS for frontend, Python/Flask for the backend, and stored information in Google PostgreSQL.",
      "Leading development on future features requested by users in collaboration with other team members.",
    ],
    tags: ["ReactJS", "REST API", "PostgreSQL", "GCP", "Material UI"],
  },
  {
    id: 5,
    company: "Tiger Graph",
    title: "Developer Advocate Intern",
    startDate: "June 2022",
    endDate: "August 2022",
    bullets: [
      "Presented analysis to AirBnB client and increased customer interest in TigerGraph by 1.5x.",
      "Created a dashboard on AirBnB data that visualized complex relationships found with machine learning models, NLP, and graph databases with a team of 5+.",
      "Developed the dashboard's Python API backend, NLP Models, and created visualizations to represent the NLP models using Plotly and Matplotlib.",
      "Assisted in removing blockers during daily stand-ups while working in SCRUM environment.",
    ],
    tags: ["Python", "NLP", "Machine Learning", "ReactJS", "SQL"],
  },
  {
    id: 6,
    company: "UCSD Recreation",
    title: "Esports Assistant",
    startDate: "June 2021",
    endDate: "June 2022",
    bullets: [
      "Helped develop and open the first Esports Cafe on UC San Diego's campus.",
      "Assisted professional esports teams by working with sponsors to get access top of the line equipment",
      "Successfully managed and collaborated with various sponsors to orchestrate events with over 200 attendees.",
    ],
  },
  {
    id: 7,
    company: "Trader Joe's",
    title: "Crew Member",
    startDate: "January 2018",
    endDate: "July 2020",
    bullets: [
      "Helped develop and open the first Esports Cafe on UC San Diego's campus.",
      "Assisted professional esports teams by working with sponsors to get access top of the line equipment",
      "Successfully managed and collaborated with various sponsors to orchestrate events with over 200 attendees.",
    ],
  },
];
